// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-future-innovations-tsx": () => import("./../../../src/pages/future-innovations.tsx" /* webpackChunkName: "component---src-pages-future-innovations-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-miqroaware-tsx": () => import("./../../../src/pages/miqroaware.tsx" /* webpackChunkName: "component---src-pages-miqroaware-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-templates-blog-page-tsx": () => import("./../../../src/templates/BlogPage.tsx" /* webpackChunkName: "component---src-templates-blog-page-tsx" */),
  "component---src-templates-executive-page-tsx": () => import("./../../../src/templates/ExecutivePage.tsx" /* webpackChunkName: "component---src-templates-executive-page-tsx" */)
}

